/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	font-family: 'Poppins', sans-serif !important;
}

ul li {
	font-family: 'Poppins', sans-serif !important;
}

.footer_contents li {
	font-weight: 200 !important;
	line-height: 2;
	font-size: 14px;
	opacity: 01;
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
	// border: 1px solid #343434 !important;
}

.hs-wrapper:hover img {
	animation-play-state: running;
}

@keyframes showMe {
	0% {
		visibility: visible;
		z-index: 100;
	}

	12.5% {
		visibility: visible;
		z-index: 100;
	}

	25% {
		visibility: hidden;
		z-index: 0;
	}

	100% {
		visibility: hidden;
		z-index: 0;
	}
}

.hs-wrapper img:nth-child(1) {
	z-index: 9;
}

.hs-wrapper img:nth-child(2) {
	animation-delay: 0.1s;
	z-index: 8;
}

.hs-wrapper img:nth-child(3) {
	animation-delay: 0.2s;
	z-index: 7;
}

.hs-wrapper img:nth-child(4) {
	animation-delay: 0.3s;
	z-index: 6;
}

.hs-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 500;
	background: rgba(0, 0, 0, 0.6);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3) inset;
	pointer-events: none;
	transition: all 0.3s linear;
}

.hs-wrapper:hover .hs-overlay {
	opacity: 1;
	box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.3) inset;
}

.hs-wrapper img {
	top: 0px;
	left: 0px;
	position: absolute;
	animation: showMe 0.8s linear infinite 0s forwards;
	animation-play-state: paused;
}

.hs-wrapper:hover img {
	animation-play-state: running;
}

// navigation sample
.navbar_sample {
	overflow: hidden;
	background-color: #5040dd;
	width: 100%
}

.navbar_sample a {
	float: left;
	font-size: 16px;
	color: white;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
}

.dropdown_sample {
	float: left;
	overflow: hidden;
}

.dropdown_sample .dropbtn {
	font-size: 14px !important;
	border: none;
	outline: none;
	color: white;
	padding: 8px 16px;
	background-color: inherit;
	font: inherit;
	margin: 0;
}

.dropdown_sample .dropbtn.active {
	font-weight: bold;
}

.navbar_sample a:hover,
.dropdown_sample:hover .dropbtn {
	// background-color: red;
}

.dropdown-content_1 {
	display: none;
	position: fixed;
	background-color: #ffffff;
	width: 100%;
	left: 0;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

//   .dropdown-content_1 .header {
// 	background: red;
// 	padding: 16px;
// 	color: white;
//   }

.dropdown_sample:hover .dropdown-content_1 {
	display: block;
}

/* Create three equal columns that floats next to each other */
.column_menu {
	float: left;
	width: auto;
	padding: 10px 10px 10px 0px;
	background-color: #ffffff;
	height: 250px;
}

.column_menu a {
	float: none;
	color: black;
	padding: 6px 15px;
	text-decoration: none;
	display: block;
	text-align: left;
	font-size: 13px;
}

.column_menu a:active,
.column_menu a:hover {
	color: #5040dd;
}

/* Clear floats after the columns */
.row:after {
	content: "";
	display: table;
	clear: both;
}

.column_menu h1 {
	color: #000000;
	padding: 6px 15px;
	font-size: 14px;
	font-weight: 600;
}

.menu_row {
	max-width: 90%;
	margin: auto
}

.button_primary {
	color: #ffffff !important;
	padding: 0.7rem 2rem !important;
	align-self: flex-start !important;
	border-radius: 8px !important;
	line-height: normal !important;
	font-weight: 700 !important;
	font-size: 14px !important;
	border: solid 1px #3723dd !important;
	font-weight: 700 !important;
	background-color: #3723dd !important;
	text-transform: capitalize !important;
}

.btn_normal {
	color: #ffffff !important;
	padding: 0.6rem 1.8rem !important;
	border-radius: 8px !important;
	background-color: #3723dd !important;
	font-size: 14px !important;
	font-weight: 700 !important;
	border: 1px solid #3723dd !important;
	min-width: 108px !important;
	text-transform: capitalize !important;
}

.btn_white {
	font-size: 14px !important;
	font-weight: 700 !important;
	background-color: #ffffff !important;
	border-radius: 8px !important;
	padding: 0.6rem 1.8rem !important;
	color: #292362 !important;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	border: 1px solid #dddddd !important;
	min-width: 108px !important;
	text-transform: capitalize !important;
}

.btn_red {
	color: white !important;
	background-color: #dd0d00 !important;
	padding: 0.6rem 1.8rem !important;
	border-radius: 8px !important;
	line-height: normal !important;
	font-size: 14px !important;
	font-weight: 700 !important;
	min-width: 108px !important;
	text-transform: capitalize !important;
}

.dropdown-content_1 {
	margin-top: 3px !important;
}

.mat-mdc-select-arrow-wrapper {
	content: url('../assets/images/down_arrowmark_black.svg') !important;
	width: 12px !important;
}

// /
@media screen and (max-width:767px) {
	.posters_title {
		font-size: 10px !important;
	}

	.posters_row {
		gap: 0.5rem !important;
	}

	.carousel_item_width {
		width: 111% !important;
	}

	.carousel_item_gap {
		gap: 30px !important;
	}

	.title_sm {
		font-size: 20px !important;
	}

	.card_title {
		font-size: 10px !important;
	}

	// .franchise_logo_sm {
	// 	border-right: 1px solid #b9b5d1;
	// }

	.gift_icon {
		margin-left: 1px !important;
	}

	.navicon_sm {
		width: 17px !important;
		height: 17px !important;
	}

	.logo_footer {
		width: 70px !important;
		height: 70px !important;
	}

	.footer_text {
		font-size: 8px !important;
	}
}

.mat-content {
	flex: none !important
}

.mat-expansion-panel-body {
	padding-left: 17px !important;
}

.mat-mdc-button {
	padding: 0px !important
}

.mat-mdc-button .mat-mdc-focus-indicator {
	background-color: white !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
	font-weight: bold !important;
	color: black !important;
}

.mat-mdc-tab .mdc-tab__text-label {
	color: black !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
	border-color: #3723dd !important;
	border-width: 3px !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
	min-height: 2.18rem !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
	border-color: #343434 !important;
	// color:red !important
}

theme-default .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.theme-default.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {


	color: #000000 !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-icon {
	color: black !important;
}

.mat-mdc-dialog-content {
	max-height: 100vh !important;
}

@media screen and (min-width:768px) and (max-width:1024px) {
	// .md_navbar {
	// 	width: 100% !important;
	// 	flex-grow: 1 !important;
	// 	justify-content: end !important;
	// 	gap: 12px !important
	// }

	.navbar_width {
		width: 100% !important;
		gap: 12px !important;
	}

	.logo_md {
		width: 100% !important;
	}

	.logo_cation {
		font-size: 15px !important;
	}

	.logo_text {
		font-size: 12px !important;
		;
	}

	.logo_title {
		margin-left: 12px !important;
	}

	.langauage_selection {
		padding: 0.4rem 1rem !important;
	}
}

/* Select the scrollbar track */
::-webkit-scrollbar-track {
	/* Change the background color */
	background-color: transparent;
}

/* Select the scrollbar thumb */
::-webkit-scrollbar-thumb {
	/* Change the background color */
	background-color: #e9e6ff !important;

	/* Change the size of the thumb */
	height: 30px;
	width: 10px;
}

/* Select the scrollbar track */
html::-webkit-scrollbar-track {
	/* Change the background color */
	background-color: transparent;
}

/* Select the scrollbar thumb */
html::-webkit-scrollbar-thumb {
	/* Change the background color */
	background-color: #e9e6ff !important;

	/* Change the size of the thumb */
	height: 30px;
	width: 10px;
}

/////Google translate

#goog-gt-tt
{
    display:none!important;
}

.skiptranslate{
	display: none !important;

}

#google_translate_element{width:300px;float:right;text-align:right;display:block}
.goog-te-banner-frame.skiptranslate { display: none !important;} 
body { top: 0px !important; }
#goog-gt-tt{display: none !important; top: 0px !important; } 
.goog-tooltip skiptranslate{display: none !important; top: 0px !important; } 
.activity-root { display: hide !important;} 
.status-message { display: hide !important;}
.started-activity-container { display: hide !important;}


// .VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
//     background-color: transparent !important;
//     box-shadow: none !important;
//     box-sizing: border-box !important;
//     -webkit-box-sizing: border-box;
//     -moz-box-sizing: border-box;
//     position: relative;
// }